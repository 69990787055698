<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-1 mt-1">
      <div class="d-flex align-items-center">
        <label for="perPageSelect" class="mr-1" style="font-size: 14px">Sort</label>
        <b-form-select id="perPageSelect" v-model="leaveTypesPage" size="sm" :options="pageOptions" class="w-25 mr-1" />
        <b-button size="sm" variant="primary" v-b-modal.addType>Add Type</b-button>
      </div>
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Search</label>
        <b-form-input id="searchInput" v-model="leaveTypesFilter" size="sm" type="search" placeholder="Search" />
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      :per-page="leaveTypesPage"
      :current-page="leaveTypesCurrentPage"
      :items="leaveTypesItem"
      :fields="leaveTypesFields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="leaveTypesFilter"
      @filtered="onFiltered"
      class="header-wrap"
    >
      <template #cell(action)="data">
        <feather-icon icon="EditIcon" v-b-modal="`detail_${data.value}`" size="20" class="text-primary" />
      </template>
    </b-table>
    <div class="d-flex justify-content-between align-items-center">
      <span>
        Showing
        {{ leaveTypesPage > leaveTypesTotalRows ? leaveTypesTotalRows : leaveTypesPage }} of
        {{ leaveTypesTotalRows }} entries
      </span>
      <b-pagination
        v-model="leaveTypesCurrentPage"
        :total-rows="leaveTypesTotalRows"
        :per-page="leaveTypesPage"
        align="right"
        size="sm"
        class="my-0"
      />
    </div>

    <b-modal id="addType" size="md" title="Add Type Leave" hide-footer>
      <b-form @submit.prevent="addType">
        <b-form-group label="Type Name">
          <b-form-input v-model="typeName" />
        </b-form-group>
        <b-form-group label="Total Days">
          <b-form-input v-model="typeDays" type="number" min="1" />
        </b-form-group>

        <div class="text-right">
          <b-button variant="primary" type="submit" :disabled="submitting">
            <div class="d-flex align-items-center" v-if="submitting">
              <b-spinner small class="mr-1" />
              Submitting...
            </div>
            <div v-else>Submit</div>
          </b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      v-for="item in leaveTypesItem"
      :key="item.id"
      :id="`detail_${item.id}`"
      size="md"
      title="Add Type Leave"
      hide-footer
    >
      <b-form @submit.prevent="addType">
        <b-form-group label="Type Name">
          <b-form-input v-model="typeName" />
        </b-form-group>
        <b-form-group label="Total Days">
          <b-form-input v-model="typeDays" type="number" min="1" />
        </b-form-group>

        <div class="text-right">
          <b-button variant="primary" type="submit" :disabled="submitting">
            <div class="d-flex align-items-center" v-if="submitting">
              <b-spinner small class="mr-1" />
              Submitting...
            </div>
            <div v-else>Submit</div>
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "LeaveType",
  data() {
    return {
      leaveTypesTotalRows: 1,
      leaveTypesCurrentPage: 1,
      leaveTypesItem: [],
      leaveTypesPage: 5,
      pageOptions: [3, 5, 10],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      leaveTypesFilter: null,
      leaveTypesFields: [
        { key: "type", label: "Type" },
        { key: "days", label: "Total Days" },
        { key: "action", label: "action" },
      ],

      typeName: "",
      typeDays: 1,

      submitting: false,
    };
  },

  mounted() {
    this.callApi({
      url: "leave-types/fetch",
      success: (res) => {
        res.result.map((item, index) => {
          this.leaveTypesItem.push({
            type: item.name,
            days: item.total_days,
            action: item.id,
          });
        });
      },
    });
  },

  methods: {
    addType() {
      this.submitting = true;
      this.callApi({
        url: "leave-types/create",
        method: "POST",
        data: {
          name: this.typeName,
          total_days: this.typeDays,
        },
        success: (res) => {
          this.leaveTypesItem.push({
            type: res.result.name,
            days: res.result.total_days,
            action: res.result.id,
          });

          this.$bvModal.hide("addType");
          this.submitting = false;

          this.typeName = "";
          this.typeDays = 1;
        },
        error: (e) => {
          this.submitting = false;
        },
      });
    },
  },
};
</script>