<template>
  <b-card-actions title="Settings" action-refresh>
    <b-tab active title="Left">
      <b-tabs>
        <b-tab active title="Leave Type">
          <LeaveType/>
        </b-tab>
        <b-tab title="User Remaining Days">
          <UserRemainingDays/>
        </b-tab>
      </b-tabs>
    </b-tab>
  </b-card-actions>
</template>

<style scoped>
.header-wrap {
  white-space: nowrap !important;
}
</style>

<script>
import LeaveType from "./SettingLeaveType.vue"
import UserRemainingDays from "./SettingUserRemainingLeave.vue"

export default {
  components: {
    LeaveType,
    UserRemainingDays
  },  
};
</script>