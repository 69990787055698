<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-1 mt-1">
      <div class="d-flex align-items-center">
        <label for="perPageSelect" class="mr-1" style="font-size: 14px">Sort</label>
        <b-form-select id="perPageSelect" v-model="page" size="sm" :options="pageOptions" class="w-25 mr-1" />
        <b-button size="sm" variant="primary" v-b-modal.assignTo>Assign To</b-button>
      </div>
      <div class="d-flex align-items-center">
        <label for="" class="mr-1" style="font-size: 14px">Search</label>
        <b-form-input id="searchInput" v-model="filter" size="sm" type="search" placeholder="Search" />
      </div>
    </div>

    <b-table
      striped
      hover
      responsive
      :per-page="page"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      @filtered="onFiltered"
      class="header-wrap"
    >
      <template #cell(action)="data">
        <feather-icon icon="EditIcon" v-b-modal="`detail_${data.value}`" size="20" class="text-primary" />
      </template>
    </b-table>
    <div class="d-flex justify-content-between align-items-center">
      <span>
        Showing
        {{ page > totalRows ? totalRows : page }} of
        {{ totalRows }} entries
      </span>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="page"
        align="right"
        size="sm"
        class="my-0"
      />
    </div>

    <b-modal id="assignTo" size="md" title="Assign to Employee" hide-footer>
      <b-form @submit.prevent="addRemaining">
        <b-form-group label="Employee">
          <v-select
            label="name"
            v-model="employee"
            :options="employees"
          />
        </b-form-group>
        <b-form-group label="Type Leave">
          <v-select
            label="name"
            v-model="leaveType"
            :options="leaveTypes"
          />
        </b-form-group>
        <b-form-group label="Total Days">
          <b-form-input v-model="remainingDays" type="number" min="1" />
        </b-form-group>

        <div class="text-right">
          <b-button variant="primary" type="submit" :disabled="submitting">
            <div class="d-flex align-items-center" v-if="submitting">
              <b-spinner small class="mr-1" />
              Submitting...
            </div>
            <div v-else>Submit</div>
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "UserRemainingDays",
  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      items: [],
      page: 5,
      pageOptions: [3, 5, 10],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      // fields: [
      //   { key: "user_id", label: "Type" },
      //   { key: "leave_type_id", label: "Total Days" },
      //   { key: "action", label: "action" },
      // ],

      leaveType: "",
      remainingDays: 1,

      leaveTypes: [],
      employees: [],

      submitting: false,
    };
  },

  mounted() {
    this.callApi({
      url: 'users/getAll',
      success: (res) => {
        res.result.filter((item) => item.id != 1).map((item) => {
          this.employees.push(item)
        })
      }
    })

    this.callApi({
      url: 'leave-types/fetch',
      success: (res) => {
        res.result.map((item) => {
          this.leaveTypes.push(item)
        })
      }
    })

    this.callApi({
      url: 'user-remaining-days/fetch',
      success: (res) => {
        res.result.map((item) => {
          this.items.push({})
        })
      }
    })
  },

  methods: {
    addRemaining() {
      this.submitting = true
      this.callApi({
        url: 'user-remaining-leaves/create',
        method: 'post',
        data: {
          user_id: this.employee.id,
          leave_type_id: this.leaveType.id,
          remaining_days: this.remainingDays
        },
        success: (res) => {
          this.$bvModal.hide('assignTo')
          this.submitting = false

          this.employee = {}
          this.leaveType = {}
          this.remainingDays = 1
        },
        error: (e) => {
          this.submitting = false
        }
      })
    }
  }
};
</script>